/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: DashboardKit
Support: dashboardkit@gmail.com
File: style.css
=========================================================================
=================================================================================== */
// theme font
// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables"; // ============================
//     pc-horizontal css start
// ============================

.pc-horizontal {
    .pc-header {
        left: 0;
        z-index: 1026;

        &.pc-mob-header {
            z-index: 1027;
        }

        &[class*="bg-"],
        &.dark-header {
            .material-icons-outlined {
                background-color: $sidebar-icon-color;
            }

            .pc-head-link {
                color: $white; //$sidebar-color;
            }
        }

        .m-header {
            @media (min-width: 1401px) {
                margin-right: 20px;
            }
        }

        .header-wrapper {
            padding: 0;

            .m-header {
                display: inline-flex;
                align-items: center;
            }
        }
    }

    .page-header {
        background: transparent;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: none;
        padding: 13px 0;

        + .row {
            margin-top: 30px;
        }
    }

    .pc-container {
        .pcoded-content {
            padding-top: 0;

            > .row:first-child {
                padding-top: 30px;
            }

            .page-header {
                padding-top: 30px;

                + .row {
                    margin-top: 10px;
                }
            }
        }
        margin-left: 0;
        top: calc(#{$header-height} + #{$topbar-height});
        min-height: calc(100vh - #{$header-height} - #{$topbar-height});
    }

    &.layout-topbar {
        .pc-header {
            &[class*="bg-"],
            &.dark-header {
                .material-icons-outlined {
                    //background-color: $sidebar-icon-color;
                }

                .pc-head-link {
                    //color: $sidebar-color;

                    &.active,
                    &:active,
                    &:focus,
                    &:hover {
                        //background: transparent;
                        //color: $primary;

                        .material-icons-outlined {
                            //background-color: $primary;
                        }
                    }
                }
            }

            .pc-h-item {
                .arrow-icon {
                    margin-left: 4px;

                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }

        .pc-container {
            top: $header-height;

            .page-header {
                padding-top: 30px;

                + .row {
                    padding-top: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    .topbar {
        background: $topbar-background;
        top: $header-height;
        left: 0;
        width: 100%;
        height: $topbar-height;
        position: fixed;
        z-index: 1025;
        box-shadow: none;
        display: flex;
        align-items: center;
        box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }

        .pc-micon {
            margin-right: 10px;
            line-height: 1;

            > svg {
                width: 20px;
                height: 20px;
            }

            .material-icons-outlined {
                line-height: 1;
                font-size: 22px;
                background-color: $sidebar-color;
            }
        }

        .pc-link {
            display: block;
            align-items: center;
            padding: 17px 10px;
            color: $topbar-color;
            font: {
                size: 14px;
                weight: 500;
            }

            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
                color: $sidebar-active-color;
            }

            &.dropdown-toggle {
                &:after {
                    display: none;
                }
            }
        }

        .pc-navbar {
            .pc-link {
                display: flex;
                align-items: center;
            }

            > .pc-item {
                > .pc-link {
                    > .pc-arrow {
                        transform: rotate(90deg);
                        margin-left: 10px;
                    }
                }
            }
        }

        .pc-item {
            display: inline-block;
        }

        .pc-arrow {
            margin-left: auto;
            float: right;
            display: inline-block;
            transition: all 0.2s ease-in-out;

            > svg {
                width: 14px;
                height: 14px;
            }
        }

        .pc-submenu {
            position: absolute;
            background: $header-submenu-background;
            padding: 15px 0;
            min-width: 200px;
            border-radius: 4px;
            animation: 0.2s ease-in-out 0s normal forwards 1 fadein;
            box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);

            .pc-item {
                display: block;
                position: relative;
            }

            .pc-link {
                color: $header-submenu-color;
                position: relative;
                padding: 12px 10px 12px 20px;
            }

            .pc-submenu {
                left: 100%;
                top: -15px;
                z-index: 1;

                &.edge {
                    left: auto;
                    right: 100%;
                }
            }
        }
        // .pc-hasmenu {
        //     &:hover {
        //         > .pc-link {
        //             .pc-arrow {
        //                 transform: rotate(90deg);
        //             }
        //         }
        //     }
        // }
    }

    .pct-customizer {
        display: none;
    }

    &.layout-horizontal-v2 {
        .topbar {
            background: $header-background;

            .pc-item {
                .pc-link {
                    color: #1c232f;

                    .material-icons-outlined {
                        background-color: #525b69;
                    }
                }
            }

            .pc-submenu {
                background: $header-submenu-background;

                .pc-link {
                    color: $header-submenu-color;
                }
            }

            .pc-hasmenu {
                &:hover {
                    > .pc-link {
                        .pc-arrow {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1401px) {
    .pc-horizontal {
        .topbar {
            .dropdown {
                &:hover {
                    > .dropdown-menu {
                        display: block;
                    }
                }
            }

            .pc-submenu {
                .pc-link {
                    position: relative;
                    padding: 12px 20px 12px 25px;
                }

                .pc-item {
                    &:active,
                    &:focus,
                    &:hover {
                        > .pc-link {
                            color: $primary;
                            background: shift-color($primary, $soft-bg-level);
                        }
                    }
                }
            }

            .pc-item {
                .pc-submenu {
                    display: none;
                }

                &:hover {
                    > .pc-submenu {
                        display: block;
                    }
                }
            }

            .pc-link {
                &:active,
                &:focus,
                &:hover {}
            }
        }

        &.layout-topbar {
            .pc-h-dropdown {
                margin-top: 0;
            }
        }
    }
    // new Dropdown style Start
    .pc-horizontal {
        .pc-container {
            .pcoded-content {
                padding-left: 0;
                padding-right: 0;
            }

            .page-header + .row {
                padding-top: 0;
            }
        }

        .topbar {
            .pc-navbar > .pc-hasmenu {
                position: relative;

                &:hover {
                    > .pc-link {
                        z-index: 1001;
                        color: $header-submenu-color;
                        position: relative;

                        .material-icons-outlined {
                            background-color: $header-submenu-color;
                        }
                    }

                    > .pc-submenu {
                        z-index: 1;
                        padding-top: 60px;
                        top: 0;
                        left: -10px;
                    }
                }
            }
        }
    }
    // new Dropdown style End
    &.layout-horizontal-v2 {
        .topbar {
            .pc-navbar {
                .pc-item {
                    > .pc-link {
                        .material-icons-outlined {
                            background-color: $sidebar-icon-color;
                        }
                    }

                    &.active,
                    &:active,
                    &:focus,
                    &:hover {
                        > .pc-link {
                            color: $primary;

                            .material-icons-outlined {
                                background-color: $primary;
                            }
                        }
                    }
                }

                > .pc-hasmenu {
                    &:hover {
                        > .pc-submenu {
                            padding-top: 15px;
                            top: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1400px) {
    .pc-horizontal {
        .topbar {
            display: none;
            height: auto;

            .container {
                max-width: 100%;
            }

            .pc-item {
                display: block;
            }

            .pc-navbar > .pc-item {
                .pc-link {
                    padding: 12px 10px;
                }

                &:first-child {
                    > .pc-link {
                        padding-left: 10px;
                    }
                }
            }

            .pc-item {
                .pc-submenu {
                    display: none;
                }

                &.pc-trigger {
                    > .pc-submenu {
                        display: block;
                    }
                }
            }

            .pc-submenu {
                position: relative;
                width: calc(100% - 30px);
                padding-top: 0;
                float: none;
                padding-bottom: 0;
                background: transparent;
                box-shadow: none;
                left: 30px;

                .pc-submenu {
                    left: 20px;
                    top: 0;
                }

                .pc-link {
                    color: $topbar-color;
                }
            }

            &.mob-sidebar-active {
                display: block;
                overflow-y: auto;
                max-height: calc(100vh - #{$header-height});
            }

            > .container {
                position: relative;
                z-index: 5;
                background: inherit;
            }
        }

        .pc-header {
            &.mob-header-active {
                .container {
                    max-width: 100%;
                    padding-left: 0;
                    padding-right: 0;

                    > .header-wrapper {
                        padding-left: 15px;
                        padding-right: -15px;

                        .m-header {
                            display: none;
                        }
                    }
                }

                #user-dropdown-menu {
                    display: block
                }
            }

            #user-dropdown-menu {
                display: none
            }

            .m-header {
                .b-brand {
                    display: none;
                }
            }
        }

        .pc-container {
            top: $header-height;
        }

        &.layout-topbar {
            .pc-header {
                .pc-mob-drp {
                    > ul {
                        display: block;

                        .pc-h-item {
                            .pc-head-link {
                                display: flex;
                            }

                            &:not(:hover) {
                                .pc-head-link {
                                    color: $header-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// pc-horizontal css end

.pc-container .pcoded-content {
    padding: 0 !important;
}

.pc-container .page-header + .row {
    padding-top: 0 !important;
}

.hamburger.is-active .hamburger-inner {
    background-color: #FFF !important
}

.hamburger.is-active .hamburger-inner::after, .hamburger.is-active .hamburger-inner::before {
    background-color: #FFF !important
}